export enum ChatRole {
  USER = 'user',
  ASSISTANT = 'assistant',
}

export type ApiMessage = {
  role: ChatRole;
  content: string;
};

export type ChatMessage = {
  role: ChatRole;
  content: string;
  date: Date;
};
