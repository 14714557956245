import { create } from 'zustand';

type ModuleData = {
  descritpion: string;
  data: any;
};

type RawDataState = {
  data: Record<string, ModuleData>;
  setChatContextData: (moduleName: string, moduleData: ModuleData) => void;
};

export const useChatDataContextStore = create<RawDataState>((set) => ({
  data: {},
  setChatContextData: (moduleName: string, moduleData: ModuleData) =>
    set((state) => ({ data: { ...state.data, [moduleName]: moduleData } })),
}));
