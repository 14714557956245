'use client';

import 'katex/dist/katex.min.css';
import './markdown-styles.css';

import clsx from 'clsx';
import { forwardRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import rehypeKatex from 'rehype-katex';
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize';
import remarkMath from 'remark-math';

import { Styled } from '@/src/app/helpers/types';

type MarkdownPreviewProps = {
  markdown: string;
  style?: React.CSSProperties;
};

export const MarkdownPreview = forwardRef<
  HTMLDivElement,
  Styled<MarkdownPreviewProps>
>(function MarkdownPreview(
  { markdown, style, className }: Styled<MarkdownPreviewProps>,
  ref,
) {
  const customSchema = {
    ...defaultSchema,
    tagNames: [
      ...(defaultSchema.tagNames ?? []),
      'u',
      'b',
      'ul',
      'ol',
      'li',
      'blockquote',
    ],
    attributes: {
      ...defaultSchema.attributes,
      ol: ['start', 'type'],
      li: ['className'],
      blockquote: ['className'],
    },
  };

  return (
    <div
      className={clsx('markdown-body [&_p]:mb-3', className)}
      style={style}
      ref={ref}
    >
      <ReactMarkdown
        remarkPlugins={[[remarkMath, { singleDollarTextMath: false }]]}
        rehypePlugins={[[rehypeSanitize, customSchema], rehypeKatex]}
        components={{
          ul: ({ node, ...props }) => (
            <ul {...props} style={{ listStyleType: 'disc' }} />
          ),

          ol: ({ node, ...props }) => (
            <ol {...props} style={{ listStyleType: 'decimal' }} />
          ),

          li: ({ node, ...props }) => <li {...props} />,

          code({ children }) {
            return (
              <SyntaxHighlighter style={dracula}>
                {String(children)}
              </SyntaxHighlighter>
            );
          },
        }}
      >
        {markdown}
      </ReactMarkdown>
    </div>
  );
});
