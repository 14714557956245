import clsx from 'clsx';

import { Styled } from '@/src/app/helpers/types';

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

export const Input = ({ type, className, ...rest }: Styled<InputProps>) => {
  return (
    <input
      className={clsx(
        'block w-full rounded-lg border-1 border-primary px-[14px] py-[10px] pr-8 leading-tight focus:outline-none',
        className,
      )}
      type={type || 'text'}
      {...rest}
    />
  );
};
