'use client';

import React, { createContext, useContext } from 'react';

type TooltipContextType = {
  availableTooltips: string[];
};

const TooltipContext = createContext<TooltipContextType | null>(null);

export const useTooltipContext = () => {
  const context = useContext(TooltipContext);
  if (!context) {
    throw new Error('useTooltipContext must be used within a TooltipProvider');
  }
  return context;
};

export const TooltipProvider: React.FC<{
  tooltipData: TooltipContextType;
  children: React.ReactNode;
}> = ({ tooltipData, children }) => {
  return (
    <TooltipContext.Provider value={tooltipData}>
      {children}
    </TooltipContext.Provider>
  );
};
