'use client';

import { Transition } from '@headlessui/react';
import { SparklesIcon } from '@heroicons/react/24/outline';
import { PaperAirplaneIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { useReducer } from 'react';

import { Input } from '@/src/common/components/advanced/form/Input';
import { useChat } from '@/src/features/chat/_hooks/useChat';
import { Brand } from '@/types/common';

import { Messages } from './Messages';

export const ChatButton = ({ brand }: { brand?: Brand }) => {
  const [isOpen, toggleOpen] = useReducer((state) => !state, false);
  const { isLoading, submitMessage, messages } = useChat();

  if (!brand) return;

  return (
    <>
      <button
        type="button"
        onClick={toggleOpen}
        className="fixed bottom-4 right-4 z-[100] h-14 w-14 rounded-full bg-green/75 px-4 py-2 text-sm font-medium text-white hover:bg-green/40 focus:outline-none"
      >
        <SparklesIcon className="h-6 w-6" />
      </button>

      <Transition show={isOpen}>
        <div className="absolute bottom-20 right-4 z-[100] flex h-[700px] max-h-[70vh] w-[600px] flex-col gap-4 rounded-lg border border-gray-8 bg-primary p-3">
          <div className="relative flex flex-1 flex-col gap-4 overflow-y-auto">
            {messages && messages.length > 0 ? (
              <Messages
                messages={messages}
                brand={brand}
                isLoading={isLoading}
              />
            ) : (
              <div className="flex h-full w-full items-center justify-center">
                <SparklesIcon className="h-1/3 text-white opacity-5" />
              </div>
            )}
          </div>

          <form
            className="flex flex-row justify-end gap-2"
            onSubmit={submitMessage}
          >
            <Input
              name="prompt"
              className="border border-gray-8 bg-gray-10 text-primary placeholder:opacity-30"
              placeholder="Ask AI..."
              autoFocus
            />
            <button type="submit" className="text-white" disabled={isLoading}>
              <PaperAirplaneIcon
                className={clsx('h-6 w-6 text-chart-green', {
                  'animate-pulse': isLoading,
                })}
              />
            </button>
          </form>
        </div>
      </Transition>
    </>
  );
};
