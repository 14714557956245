import clsx from 'clsx';
import { format } from 'date-fns';
import { ReactNode } from 'react';

import { BrandLogo } from '@/src/common/components/BrandLogo';
import { CovarianceBrand } from '@/src/constants';
import { MarkdownPreview } from '@/src/features/markdown/_components/MarkdownPreview';
import { Brand } from '@/types/common';

import { ChatMessage as ApiChatMessage } from '../types';

type ChatMessage = {
  charts?: ReactNode[];
  date: Date;
} & ApiChatMessage;

export const Messages = ({
  messages = [],
  brand,
  isLoading = false,
}: {
  messages?: ChatMessage[];
  brand: Brand;
  isLoading?: boolean;
}) => {
  return (
    <div className="flex h-full w-full flex-col gap-3xl overflow-y-auto pr-2">
      {messages.map((message, index) => (
        <div
          className="flex w-full gap-lg text-primary"
          key={message.date.getTime() + message.role}
          ref={
            index === messages.length - 1
              ? (el) => {
                  el?.scrollIntoView();
                }
              : undefined
          }
        >
          <div className="shrink-0">
            <BrandLogo
              brand={message.role == 'user' ? brand : CovarianceBrand}
              size={10}
            />
          </div>
          <div className="flex w-full flex-col gap-2">
            <div className="flex justify-between">
              <div className="flex-sm text-secondary">
                {message.role == 'user' ? brand.name : 'Covariance Agent'}
              </div>
              <div className="text-xs text-tertiary">
                {format(message.date, 'EEEE h:mma')}
              </div>
            </div>
            <div className="rounded-xl border-1 border-gray-8 px-3 py-2">
              <MarkdownPreview
                markdown={message.content}
                className={clsx('px-2', '[&_span.katex]:text-md')}
              />
              {message.charts?.map((chart) => chart)}
            </div>
          </div>
        </div>
      ))}
      {isLoading && (
        <div className="m-2 mr-12 w-12 animate-pulse self-start rounded-lg bg-gray-8 p-2 text-center text-white">
          ...
        </div>
      )}
    </div>
  );
};
