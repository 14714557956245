export function formDataToObject<T extends Record<string, any>>(
  formData: FormData,
): T {
  const result: Record<string, any> = {};

  for (const [key, value] of formData.entries()) {
    const arrayMatch = key.match(/(.+)\[\d+\]$/);
    const objectMatch = key.match(/(.+)\[(.+)\]$/);

    if (arrayMatch) {
      const baseKey = arrayMatch[1];
      if (!result[baseKey]) {
        result[baseKey] = [];
      }
      if (Array.isArray(result[baseKey])) {
        result[baseKey].push(value);
      }
    } else if (objectMatch) {
      const [_, baseKey, propKey] = objectMatch;
      if (!result[baseKey]) {
        result[baseKey] = {};
      }
      result[baseKey][propKey] = value;
    } else {
      result[key] = value;
    }
  }

  return result as T;
}
